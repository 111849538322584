<template>
  <nav>
    <ProjectTheme :project="project">
      <SidebarBack class="my-8" @back="$router.push({ name: PROJECT_DASHBOARD })">
        {{ $t('settings.back-to-project') }}
      </SidebarBack>
      <SidebarGroup label="Project Settings">
        <SidebarMenu>
          <SidebarMenuLink
            :to="{ name: PROJECT_SETTINGS_DETAILS }"
          >
            <SidebarItemBasic icon="icon_v2-so_filter-eq" label="Details" />
          </SidebarMenuLink>
          <SidebarMenuLink
            :to="{ name: PROJECT_SETTINGS_BRANDING }"
          >
            <SidebarItemBasic icon="icon_v2-so_paint-bucket" label="Branding" />
          </SidebarMenuLink>
          <SidebarMenuLink
            :to="{ name: PROJECT_SETTINGS_MODULES }"
          >
            <SidebarItemBasic icon="icon_v2-so_modules" label="Modules" />
          </SidebarMenuLink>
          <SidebarMenuLink
            :to="{ name: PROJECT_SETTINGS_MANAGE_ACCESS }"
          >
            <SidebarItemBasic icon="icon_v2-so_user-block" label="Manage Access" />
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>
      <SidebarGroup label="Content Settings">
        <SidebarMenu>
          <!-- pinned folders -->
          <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_PINNED_FOLDERS }">
            <SidebarItemBasic icon="icon_v2-so_pin" label="Pinned Folders" />
          </SidebarMenuLink>

          <!-- quick links -->
          <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_QUICK_LINKS }">
            <SidebarItemBasic icon="icon_v2-so_interactive" label="Quick Links" />
          </SidebarMenuLink>

          <!-- embedded apps -->
          <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_APPS }">
            <SidebarItemBasic icon="icon_v2-so_apps" label="Embedded Apps" />
          </SidebarMenuLink>

          <!-- integrations -->
          <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_INTEGRATIONS }">
            <SidebarItemBasic icon="icon_v2-so_diamond" label="Integrations" />
          </SidebarMenuLink>

          <!-- packaged services -->
          <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_PACKAGED_SERVICES }">
            <SidebarItemBasic icon="icon_v2-so_open-box" label="Packaged Services" />
          </SidebarMenuLink>

          <!-- pages -->
          <SidebarMenuLink :to="{ name: PROJECT_SETTINGS_PAGES }">
            <SidebarItemBasic icon="icon_v2-so_docs-files" label="Pages" />
          </SidebarMenuLink>
        </SidebarMenu>
      </SidebarGroup>
    </ProjectTheme>
  </nav>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  PROJECT_DASHBOARD,
  PROJECT_SETTINGS,
  PROJECT_SETTINGS_PINNED_FOLDERS,
  PROJECT_SETTINGS_QUICK_LINKS,
  PROJECT_SETTINGS_APPS,
  PROJECT_SETTINGS_INTEGRATIONS,
  PROJECT_SETTINGS_PACKAGED_SERVICES,
  PROJECT_SETTINGS_PAGES,
  PROJECT_SETTINGS_DETAILS,
  PROJECT_SETTINGS_BRANDING,
  PROJECT_SETTINGS_MODULES,
  PROJECT_SETTINGS_MANAGE_ACCESS,
} from '@/router/project/type'
import SidebarGroup from '@/components/Sidebar/SidebarGroup.vue'
import SidebarMenu from '@/components/Sidebar/SidebarMenu.vue'
import SidebarMenuLink from '@/components/Sidebar/SidebarMenuLink.vue'
import SidebarItemBasic from '@/components/Sidebar/SidebarItemBasic.vue'
import ProjectTheme from '@/components/ProjectTheme.vue'
import SidebarBack from './Sidebar/SidebarBack.vue'

export default defineComponent({
  name: 'ProjectNavSettings',
  components: {
    SidebarGroup,
    SidebarMenu,
    SidebarMenuLink,
    SidebarItemBasic,
    ProjectTheme,
    SidebarBack,
  },
  props: {
    organization: {
      type: Object,
      required: true,
    },
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      PROJECT_DASHBOARD,
      PROJECT_SETTINGS,
      PROJECT_SETTINGS_PINNED_FOLDERS,
      PROJECT_SETTINGS_QUICK_LINKS,
      PROJECT_SETTINGS_APPS,
      PROJECT_SETTINGS_INTEGRATIONS,
      PROJECT_SETTINGS_PACKAGED_SERVICES,
      PROJECT_SETTINGS_PAGES,
      PROJECT_SETTINGS_DETAILS,
      PROJECT_SETTINGS_BRANDING,
      PROJECT_SETTINGS_MODULES,
      PROJECT_SETTINGS_MANAGE_ACCESS,
    }
  },
})
</script>
