<template>
  <li
    v-if="value"
    class="module"
    :class="{
      'border-green-500 hover:border-green-600': value.enabled,
      'hover:border-gray-300': !value.enabled
    }"
  >
    <div class="border-b dark:border-b-darkGray-900 p-12 flex items-center justify-between">
      <h2 class="font-semibold text-14 flex items-center gap-4">
        <span class="icon_v2-so_modules text-18" />
        {{ moduleName }}
      </h2>
      <span
        v-if="value.enabledPortal"
        v-b-tooltip.hover.top.v-info.dh0.ds200="'This module is currently visible on the portal'"
        class="icon_v2-so_eye text-green-500 text-20"
      />
    </div>
    <div class="p-12 text-14">
      <div class="grid grid-cols-2 gap-8">
        <!-- toggles -->
        <div class="flex flex-col justify-center">
          <div v-if="features.enabled">
            <b-form-checkbox
              :checked="value.enabled"
              name="check-button"
              switch
              size="sm"
              class="flex items-center"
              @change="val => update('enabled', val)"
            >
              Your team
            </b-form-checkbox>
          </div>
          <div v-if="features.enabledPortal">
            <b-form-checkbox
              :checked="value.enabledPortal"
              :disabled="!value.enabled"
              name="check-button"
              switch
              size="sm"
              class="flex items-center"
              :class="{ 'opacity-40': !value.enabled }"
              @change="val => update('enabledPortal', val)"
            >
              Client
            </b-form-checkbox>
          </div>
        </div>

        <!-- form -->
        <div class="form-grid">
          <template v-if="features.icon">
            <label>Icon</label>
            <div class="flex items-center gap-4">
              <DropdownIcons
                size="sm"
                :current-icon="value.icon || value.defaultIcon"
                @pick-icon="val => update('icon', val)"
              />
              <ButtonClear @click="update('icon', defaultIcon)" />
            </div>
          </template>

          <template v-if="features.title">
            <label>Custom Title</label>
            <b-input
              :value="value.title"
              :placeholder="moduleName"
              @input="val => update('title', val)"
            />
          </template>
        </div>
      </div>
    </div>
    <div v-if="$slots.description" class="p-12 text-12 bg-gray-200 border-t">
      <slot name="description" />
    </div>
  </li>
</template>
<script>
import { computed, defineComponent } from '@vue/composition-api'
import DropdownIcons from '@/components/DropdownIcons.vue'
import ButtonClear from '@/components/ButtonClear.vue'
import { isNotNil } from 'ramda-adjunct'

export default defineComponent({
  name: 'ProjectSettingsModule',
  components: {
    DropdownIcons,
    ButtonClear,
  },
  props: {
    moduleName: {
      type: String,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    defaultIcon: {
      type: String,
      default: null,
    },
  },
  setup(props, context) {
    const update = (key, value) => {
      const updateData = { [key]: value }

      if (key === 'enabled' && !value) {
        updateData.enabledPortal = false
      }

      context.emit('update', updateData)
    }

    const features = computed(() => ({
      icon: isNotNil(props.value.icon),
      title: isNotNil(props.value.title),
      enabled: isNotNil(props.value.enabled),
      enabledPortal: isNotNil(props.value.enabledPortal),
    }))

    return {
      update,
      features,
    }
  },
})
</script>
<style lang="postcss" scoped>
.module {
  @apply bg-gray-100/50 rounded-sm border;
  @apply text-gray-500 shadow-sm;
}

.form-grid {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 4px;
  align-items: center;

  input {
    @apply text-14;
  }

  label {
    @apply pr-8 text-14 text-right text-gray-400;
  }
}

</style>
