
<CheckMemberRole
  v-slot="{ hasRole: isProjectAdmin }"
  :roles="['admin', 'projectManager']"
>
  <div class="flex items-center justify-between w-full">
    <router-link
      v-slot="{ navigate }"
      :to="{ name: PROJECT_DASHBOARD }"
      custom
    >
      <ButtonTransparent @click="navigate">
        <ProjectSwitcherItem :project="project" @click="navigate" />
      </ButtonTransparent>
    </router-link>
    <ProjectMenu
      v-if="isProjectAdmin"
      :project="project"
      class="-mr-8"
    />
  </div>
</CheckMemberRole>
