<template>
  <CheckMemberRole
    v-slot="{ hasRole: isProjectAdmin }"
    :roles="['admin', 'projectManager']"
  >
    <div class="flex items-center justify-between w-full">
      <router-link
        v-slot="{ navigate }"
        :to="{ name: PROJECT_DASHBOARD }"
        custom
      >
        <ButtonTransparent @click="navigate">
          <ProjectSwitcherItem :project="project" @click="navigate" />
        </ButtonTransparent>
      </router-link>
      <ProjectMenu
        v-if="isProjectAdmin"
        :project="project"
        class="-mr-8"
      />
    </div>
  </CheckMemberRole>
</template>
<script>
import { defineComponent } from '@vue/composition-api'
import {
  PROJECT_SETTINGS,
  PROJECT_DASHBOARD,
} from '@/router/project/type'
import ProjectSwitcherItem from '@/components/ProjectSwitcherItem.vue'
import CheckMemberRole from '@/components/CheckMemberRole.vue'
import ButtonTransparent from '@/components/ButtonTransparent.vue'
import ProjectMenu from './ProjectMenu.vue'

export default defineComponent({
  name: 'ProjectNavHeader',
  components: {
    ProjectSwitcherItem,
    CheckMemberRole,
    ButtonTransparent,
    ProjectMenu,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      PROJECT_SETTINGS,
      PROJECT_DASHBOARD,
    }
  },
})
</script>
