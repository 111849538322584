
<div v-if="organization" class="project-page project-page-wrapper">
  <ProjectViewContext v-slot="{ viewContext, updateViewContext }">
    <div>
      <OrganizationProjectsPageHeader
        :view-context="viewContext"
        :keyword.sync="keyword"
        :tags="tags"
        :selected-tag-count="selectedTagCount"
        :selected-tags.sync="selectedTags"
        @update-view-context="updateViewContext"
      />
      <!-- Content -->
      <section v-if="!isTagsPending">
        <ProjectList
          :keyword="keyword"
          :selected-tags="selectedTags"
          :organization-id="organization._id"
          class="mb-32"
          :view-context="viewContext"
          :query="activeProjectsQuery"
          :header-collapsed="viewContext.activeCollapsed"
          @update:header-collapsed="val => updateViewContext({ activeCollapsed: val })"
          @open-project="open"
        >
          <template #header>
            <h2 class="text-18 mb-0 font-semibold text-gray-900">Active Projects</h2>
          </template>
        </ProjectList>
        <ProjectList
          :keyword="keyword"
          :selected-tags="selectedTags"
          :organization-id="organization._id"
          class="mb-32"
          :view-context="viewContext"
          :config="{ createButton: false, menu: true }"
          :query="archivedProjectsQuery"
          :menu-items="{
            archive: true,
            remove: true,
            duplicate: false,
            previewPortal: false,
            settings: false,
          }"
          :header-collapsed="viewContext.archivedCollapsed"
          @update:header-collapsed="val => updateViewContext({ archivedCollapsed: val })"
        >
          <template #header="{ projects }">
            <h2
              v-if="projects?.length"
              class="text-18 mb-0 font-semibold text-gray-900"
            >
              Archived Projects
            </h2>
          </template>
        </ProjectList>
      </section>
    </div>
  </ProjectViewContext>
</div>
